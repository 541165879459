import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";

Vue.use(VueRouter);

// router guard function to check if user is already logged in before entering login route
const checkLoggedIn = (to, from, next) => {
  if (localStorage.getItem("token")) {
    next({ name: "EmployerDashboard" });
  } else {
    next();
  }
};
// // check if user have atleast one employer profile
// const checkEmployer = (to, from, next) => {
//   if (
//     localStorage.getItem("employer") ||
//     store.getters["employers/getEmployer"]
//   ) {
//     next();
//   } else {
//     next({ name: "UserOnboarding" });
//   }
// };

//router guard to check if employer is ready to post jobs before entering new job route
const checkEmployerReady = async (to, from, next) => {
  // current employer
  const emp = localStorage.getItem("employer");

  if (emp === null) {
    next();
  } else {
    const employer = JSON.parse(emp);
    // check if employer is ready to post jobs
    const isReady = await axios.get(`/api/employers/${employer.id}/ready/`);
    // after response, check if employer is ready to post jobs
    if (isReady.data.ready) {
      next();
    } else {
      //navigate to previous route
      next(from.path);
      // buefy toast open message
      Toast.open({
        message: isReady.data.message,
        type: "is-danger",
        queue: false,
        duration: 5000,
      });
    }
  }
};

// router guard to check if user can add another employer profile
const checkAddEmployer = async (to, from, next) => {
  const authorization = `Token ${localStorage.getItem("token")}`;
  // check if user is ready to add another employer profile
  const isReady = await axios.get("/api/add-employer/", {
    headers: { Authorization: authorization },
  });
  // after response, check if user is ready to add another employer profile
  if (isReady.data.success) {
    next();
  } else {
    //navigate to previous route
    next(from.path);
    // buefy toast open message
    Toast.open({
      message: isReady.data.message,
      type: "is-danger",
      queue: false,
      duration: 5000,
    });
  }
};

// check if employer sector is set before entering plans route
const checkSector = (to, from, next) => {
  // current employer
  const employer = JSON.parse(localStorage.getItem("employer"));
  // check if employer sector is set
  if (employer.sector) {
    next();
  } else {
    //navigate to previous route
    next(from.path);
    // buefy toast open message
    Toast.open({
      message:
        "You are not allowed to enter this route. Please try again later",
      type: "is-danger",
      queue: false,
      duration: 5000,
    });
  }
};

const routes = [
  {
    path: "/",
    name: "User",
    component: () => import("../layouts/LoginLayout.vue"),
    meta: {
      requiresAuth: false,
      name: "User | iulaanu.com",
    },
    redirect: "/user/login",
    children: [
      {
        path: "/user/login",
        name: "UserLogin",
        component: () => import("../views/login/UserLogin.vue"),
        meta: {
          requiresAuth: false,
          name: "User Login | iulaanu.com",
        },
        //before enter
        beforeEnter: checkLoggedIn,
      },
      {
        path: "/user/activate/:uid/:token",
        name: "UserActivation",
        component: () => import("../views/login/UserActivation.vue"),
        meta: {
          requiresAuth: false,
          name: "User Activate | iulaanu.com",
        },
        //before enter
        beforeEnter: checkLoggedIn,
      },
      {
        path: "/user/signup",
        name: "UserSignup",
        component: () => import("../views/login/UserSignup.vue"),
        meta: {
          requiresAuth: false,
          name: "User Signup | iulaanu.com",
        },
        //before enter
        beforeEnter: checkLoggedIn,
      },
      {
        path: "/user/resetPassword",
        name: "UserResetPassword",
        component: () => import("../views/login/UserResetpassword.vue"),
        meta: {
          requiresAuth: false,
          name: "User password reset | iulaanu.com",
        },
        //before enter
        beforeEnter: checkLoggedIn,
      },
      {
        path: "/user/password/reset/confirm/:uid/:token",
        name: "UserResetConfirm",
        component: () => import("../views/login/UserResetConfirm.vue"),
        meta: {
          requiresAuth: false,
          name: "User password reset confirm | iulaanu.com",
        },
        //before enter
        beforeEnter: checkLoggedIn,
      },
      {
        path: "/user/onboarding",
        name: "UserOnboarding",
        component: () => import("../views/login/UserOnboarding.vue"),
        meta: {
          requiresAuth: false,
          name: "User Onboarding | iulaanu.com",
        },
        //before enter
        beforeEnter: checkAddEmployer,
      },
    ],
  },
  {
    path: "/employer",
    name: "Employer",
    component: () => import("../layouts/AdminLayout.vue"),
    meta: {
      requiresAuth: true,
      name: "Employer Portal | iulaanu.com",
    },
    redirect: "/employer/dashboard",
    children: [
      {
        path: "/employer/dashboard",
        name: "EmployerDashboard",
        component: () => import("../views/admin/TheDashboard.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer Dashboard | iulaanu.com",
        },
      },
      {
        path: "/employer/jobs",
        name: "EmployerJobs",
        component: () => import("../views/admin/TheJobs.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer Jobs | iulaanu.com",
        },
      },
      {
        path: "/employer/jobs/create",
        name: "CreateJob",
        component: () => import("../views/admin/CreateJob.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer create job | iulaanu.com",
        },
        //before enter
        beforeEnter: checkEmployerReady,
      },
      {
        path: "/employer/jobs/:id",
        name: "JobDetail",
        component: () => import("../views/admin/JobDetail.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer job detail | iulaanu.com",
        },
      },
      {
        path: "/employer/jobs/:id/edit",
        name: "JobEdit",
        component: () => import("../views/admin/JobEdit.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer job edit | iulaanu.com",
        },
      },
      {
        path: "/employer/profile",
        name: "EmployerProfile",
        component: () => import("../views/admin/TheProfile.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer profile | iulaanu.com",
        },
      },
      {
        path: "/employer/profile/edit",
        name: "EmployerProfileEdit",
        component: () => import("../views/admin/EditProfile.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer profile edit | iulaanu.com",
        },
      },
      {
        path: "/employer/billing",
        name: "EmployerBilling",
        component: () => import("../views/admin/TheBilling.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer billing | iulaanu.com",
        },
      },
      {
        path: "/employer/billing/:id",
        name: "EmployerBillDetail",
        component: () => import("../views/admin/BillDetail.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer billing detail | iulaanu.com",
        },
      },
      {
        path: "/employer/plans",
        name: "EmployerPlans",
        component: () => import("../views/admin/ThePlans.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer plans | iulaanu.com",
        },
        //before enter
        beforeEnter: checkSector,
      },
      {
        path: "/employer/feedback",
        name: "EmployerFeedback",
        component: () => import("../views/admin/TheFeedback.vue"),
        meta: {
          requiresAuth: true,
          name: "Employer feedback | iulaanu.com",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//scroll to top on each route enter

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let token = localStorage.getItem("token");

    if (!token) {
      window.scrollTo(0, 0);
      next({
        path: "/user/login",
        query: { redirect: to.fullPath },
      });
    } else {
      store.dispatch("setLoading", true);
      let emp = localStorage.getItem("employer");
      if (emp) {
        // unset loading and proceed
        store.dispatch("setLoading", false);
        next();
      } else {
        //fetch and check if there is an employer
        store.dispatch("employers/fetchEmployers").then(() => {
          window.scrollTo(0, 0);
          if (store.getters["employers/getEmployer"]) {
            // unset loading and proceed
            store.dispatch("setLoading", false);
            next();
          } else {
            // unset loading and redirect to onboarding
            store.dispatch("setLoading", false);
            console.log("employer not found");
            next({
              path: "/user/onboarding",
              query: { redirect: to.fullPath },
            });
          }
        });
      }
    }
  } else {
    window.scrollTo(0, 0);
    next();
  }

  // set page title to current route meta title
  if (to.meta.name) {
    document.title = to.meta.name;
  } else {
    document.title = "iulaanu.com | Job Portal";
  }
});

export default router;
