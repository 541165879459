import Vue from "vue";
import Beufy from "buefy";
// import "buefy/dist/buefy.css";
import VueApexCharts from "vue-apexcharts";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from "./config/axios_config.js";

require("./assets/main.scss");

Vue.config.productionTip = false;
Vue.use(Beufy);

//apex chart setting
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

new Vue({
  store,
  router,
  axios,
  render: (h) => h(App),
}).$mount("#app");
