import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import employersModule from "./modules/employers";
import jobsModule from "./modules/jobs";
import invoicesModule from "./modules/invoices";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    isLoggedIn: false,
    token: "",
    user: {
      id: "",
      email: "",
      is_admin: false,
      is_staff: false,
    },
  },
  mutations: {
    INIT_STORE(state) {
      if (localStorage.getItem("token")) {
        state.isLoggedIn = true;
        state.token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Token " + state.token;
        state.user = JSON.parse(localStorage.getItem("user"));
      } else {
        state.isLoggedIn = false;
        state.token = "";
        axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem("user");
        localStorage.removeItem("employer");
        localStorage.removeItem("employers");
        state.user = {
          id: "",
          email: "",
          is_admin: false,
          is_staff: false,
        };
      }
    },
    LOGIN(state) {
      state.isLoggedIn = true;
    },
    LOGOUT(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("employer");
      localStorage.removeItem("employers");
      state.isLoggedIn = false;
      state.token = "";
      state.user = {
        id: "",
        email: "",
        is_admin: false,
        is_staff: false,
      };
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    logIn(context, token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      context.commit("LOGIN");
      context.commit("SET_TOKEN", token);
    },
    logOut(context) {
      axios.defaults.headers.common["Authorization"] = "";
      context.commit("LOGOUT");
    },
    setLoading(context, isLoading) {
      context.commit("SET_LOADING", isLoading);
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    isLoading: (state) => state.isLoading,
    token: (state) => state.token,
    user: (state) => state.user,
  },
  modules: {
    employers: employersModule,
    jobs: jobsModule,
    invoices: invoicesModule,
  },
});
