import axios from "../../config/axios_config";

export default {
  namespaced: true,
  state: {
    invoices: [],
  },
  mutations: {
    //
    setInvoices(state, payload) {
      payload.forEach((inv) => {
        state.invoices.push(inv);
      });
    },
    resetInvoices(state) {
      state.invoices = [];
    },
  },
  actions: {
    //
    async fetchInvoices({ commit }, id) {
      await axios
        .get("/api/invoices/?emp=" + id)
        .then((response) => {
          commit("resetInvoices");
          commit("setInvoices", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  getters: {
    getInvoices: (state) => state.invoices,
    getInvoice: (state) => (id) => {
      return state.invoices.find((inv) => inv.id == id);
    },
    getPendingInvoices: (state) =>
      state.invoices.filter((inv) => inv.status !== "3"),
    getPaidInvoices: (state) =>
      state.invoices.filter((inv) => inv.status === "3"),
  },
};
