import axios from "../../config/axios_config";

export default {
  namespaced: true,
  state: {
    employer: null,
    employers: [],
    showNotification: true,
  },
  mutations: {
    INIT_EMPLOYER(state) {
      if (localStorage.getItem("employer")) {
        state.employer = JSON.parse(localStorage.getItem("employer"));
      } else {
        state.employer = state.employers[0];
        if (state.employer) {
          localStorage.setItem("employer", JSON.stringify(state.employer));
        }
      }
    },
    INIT_EMPDATA(state) {
      // fetch jobs and invoices
      this.dispatch("jobs/fetchJobs", state.employer.id);
      this.dispatch("invoices/fetchInvoices", state.employer.id);
    },
    INIT_EMPLOYERS(state) {
      if (localStorage.getItem("employers")) {
        state.employers = JSON.parse(localStorage.getItem("employers"));
      } else {
        state.employers = [];
      }
    },
    //
    setEmployers(state, payload) {
      payload.forEach((employer) => {
        state.employers.push(employer);
      });
      // set employers in local storage
      localStorage.setItem("employers", JSON.stringify(state.employers));
    },
    setEmployer(state, payload) {
      let emp = state.employers.find((emp) => emp.id === payload);
      state.employer = emp;
      localStorage.setItem("employer", JSON.stringify(emp));
    },
    updateEmployer(state, payload) {
      state.employer = payload;
      localStorage.setItem("employer", JSON.stringify(payload));
    },
    resetEmployers(state) {
      state.employers = [];
    },
    showNotification(state, payload) {
      state.showNotification = payload;
    },
  },
  actions: {
    setActiveEmployer(state, payload) {
      state.commit("setEmployer", payload);
      this.dispatch("jobs/fetchJobs", payload);
      this.dispatch("invoices/fetchInvoices", payload);
    },
    async fetchEmployers({ commit }) {
      await axios
        .get("/api/employers/")
        .then((response) => {
          commit("resetEmployers");
          commit("setEmployers", response.data);
          // commit("setEmployer", response.data[0].id);
          commit("INIT_EMPLOYER");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async updateEmployer({ commit }, payload) {
      await axios.get(`/api/employers/${payload}/`).then((response) => {
        commit("updateEmployer", response.data);
      });
      this.dispatch("employers/fetchEmployers");
    },
    async hasEmployer() {
      if (localStorage.getItem("employer")) {
        return true;
      } else {
        await axios
          .get("/api/employers/")
          .then((response) => {
            let emp = response.data[0];
            if (emp) {
              return true;
            } else {
              return false;
            }
          })
          .catch((error) => {
            return error;
          });
      }
    },
  },
  getters: {
    getEmployer: (state) => state.employer,
    getEmployerPercentage: (state) => {
      if (state.employer === null) {
        return 0;
      } else {
        let total = 10;
        let completed = 0;
        if (state.employer.reg_no) {
          completed++;
        }
        if (state.employer.name) {
          completed++;
        }
        if (state.employer.email) {
          completed++;
        }
        if (state.employer.phone) {
          completed++;
        }
        if (state.employer.address) {
          completed++;
        }
        if (state.employer.logo) {
          completed++;
        }
        if (state.employer.intro) {
          completed++;
        }
        if (state.employer.org_size) {
          completed++;
        }
        if (state.employer.sector) {
          completed++;
        }
        if (state.employer.location) {
          completed++;
        }

        return Math.round((completed / total) * 100);
      }
    },
    getEmployers: (state) => state.employers,
    showNotification: (state) => state.showNotification,
  },
};
