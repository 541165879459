import axios from "../../config/axios_config";

export default {
  namespaced: true,
  state: {
    jobs: [],
  },
  mutations: {
    //
    setJobs(state, payload) {
      payload.forEach((job) => {
        state.jobs.push(job);
      });
    },
    resetJobs(state) {
      state.jobs = [];
    },
  },
  actions: {
    //
    async fetchJobs({ commit }, id) {
      await axios
        .get("/api/my-jobs/" + id + "/")
        .then((response) => {
          commit("resetJobs");
          commit("setJobs", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  getters: {
    getJobs: (state) => state.jobs,
    getActiveJobs: (state) =>
      state.jobs.filter(
        (job) => new Date(job.deadline) > new Date() && job.status === "1"
      ),
    getClosedJobs: (state) =>
      state.jobs.filter(
        (job) => new Date(job.deadline) < new Date() && job.status === "1"
      ),
    getDraftJobs: (state) =>
      state.jobs.filter(
        (job) => job.status === "2" || job.status === "3" || job.status === "4"
      ),
    getCurrentJobs: (state) =>
      state.jobs.filter(
        (job) =>
          (new Date(job.deadline) > new Date() && job.status === "1") ||
          job.status === "2" ||
          job.status === "3" ||
          job.status === "4"
      ),
  },
};
